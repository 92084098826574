<template>
  <div>
    <!-- Start Header Area -->
    <HeaderThree class="personal-portfolio">
      <img slot="logo" :src="logo" alt="logo" />
    </HeaderThree>
    <!-- End Header Area -->

    <!-- Start Slider Area  -->
    <div class="rn-slider-area personal-portfolio-slider-area">
      <div
        class="justify-center slide slider_fixed_height slider-style-3 d-flex align-center bg_image bg_image--1 white-overlay-mobile"
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="text-left inner">
                <span class="theme-gradient font-500"
                  ><strong>Instructor / Full Stack Developer</strong></span
                >
                <h1 class="heading-title">
                  Hello, I’m <span>Cody Squadroni.</span>
                </h1>
                <p class="description">
                  This website was created to keep track of my information
                  <br />
                  that I will be covering while working on my Ph.D in Learning,
                  Design and Technology
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <!-- End Slider Area -->

    <!-- Start About Area  -->
    <div class="about-area ptb--120 bg_color--1">
      <div class="about-wrapper">
        <div class="container">
          <AboutFive />
        </div>
      </div>
    </div>
    <!-- Start About Area  -->

    <!-- Start Portfolio Area -->
    <div class="portfolio-area pb--60 bg_color--1">
      <div class="portfolio-sacousel-inner mb--55 mb_sm--0">
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="text-center section-title mb--30 mb_sm--0">
                <span class="subtitle">My Courses</span>
                <h2 class="heading-title">My Recent Work</h2>
                <p class="description">
                  Listed below are my courses I am taking on my Ph.D journey.<br />
                  Click a module to view more.
                </p>
              </div>
            </v-col>
          </v-row>
          <PortfolioFive />
        </v-container>
      </div>
    </div>
    <!-- End Portfolio Area -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
import HeaderThree from "../components/header/HeaderThree";
import AboutFive from "../components/about/AboutFive";
import PortfolioFive from "../components/portfolio/PortfolioFive";
import Footer from "../components/footer/FooterTwo";

export default {
  components: {
    HeaderThree,
    AboutFive,
    PortfolioFive,
    Footer,
  },
  data() {
    return {
      logo: require("../assets/images/logo/logo.png"),
    };
  },
};
</script>

<style lang="scss">
.no-spacer-inside-brand {
  .row.mt--20 {
    margin-top: 0 !important;
  }
}
</style>
