<template>
  <v-row class="row--35 align-center">
    <v-col md="5" sm="12" cols="12">
      <div class="thumbnail">
        <img
          class="w-100"
          src="../../assets/images/about/aboutme.jpg"
          alt="Cody Squadroni"
        />
      </div>
    </v-col>
    <v-col md="7" sm="12" cols="12">
      <div class="about-inner inner">
        <div class="section-title">
          <span class="subtitle">Learn A Little About Me</span>
          <h2 class="heading-title">About Me</h2>
          <p class="description">
            I began my career serving in the U.S. Army National Guard, where my
            interest in technology was sparked. After I got out of the military,
            I pursued multiple degrees and certifications in computer
            information systems and computer science. As a programmer, I utilize
            creativity, leadership, problem-solving, and teamwork to design and
            execute solutions for computer-related projects such as design, user
            experience, web and software applications. I’m committed to outside
            learning whenever and however possible to continue my education and
            increase my value to my employer. My areas of expertise include
            management, technical support, instructional design, and programming
            in both academia and industry. I am detail-oriented, accurate, and
            dependable, with an uncompromising work ethic, and I strive to
            understand new concepts to provide exceptional results.
          </p>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
