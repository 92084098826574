<template>
  <v-row>
    <!-- Start Single Portfolio  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      v-for="(item, i) in portfolioContent"
      :key="i"
      class="mb--30"
    >
      <div class="im_portfolio">
        <div class="thumbnail_inner">
          <div class="thumbnail">
            <a href="portfolio-details.html">
              <img class="w-100" :src="item.src" alt="Portfolio Images" />
            </a>
          </div>
        </div>
        <div class="content">
          <div class="inner">
            <div class="portfolio_heading">
              <div class="category_list">
                <router-link to="/portfolio-details">{{
                  item.category
                }}</router-link>
              </div>
              <h4 class="heading-title">
                <router-link to="/portfolio-details">{{
                  item.title
                }}</router-link>
              </h4>
            </div>
          </div>
        </div>
        <router-link class="transparent_link" :to="item.to"></router-link>
      </div>
    </v-col>
    <!-- End Single Portfolio  -->
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      portfolioContent: [
        {
          src: require("../../assets/images/colors/color1.jpg"),
          category: "LDTE 5000",
          title: "Theoretical Foundations in LDT",
          to: "/LDTE5000",
        },
        {
          src: require("../../assets/images/colors/color2.jpg"),
          category: "LDTE 5020",
          title: "Technology and the Mind",
          to: "/LDTE5020",
        },
        {
          src: require("../../assets/images/colors/color3.jpg"),
          category: "LDTE 5870",
          title: "Technology & Learning Innovations",
          to: "/LDTE5870",
        },
        {
          src: require("../../assets/images/colors/color1.jpg"),
          category: "LDTE 5870",
          title: "Instructional Design Application",
          to: "/LDTE5870-2",
        },
        {
          src: require("../../assets/images/colors/color2.jpg"),
          category: "LDTE 5870",
          title: "Issues, Practices, and Research",
          to: "/LDTE5870-3",
        },
        {
          src: require("../../assets/images/colors/color3.jpg"),
          category: "LDTE 5210",
          title: "Learning Experience Design",
          to: "/LDTE5210",
        },
      ],
    };
  },
};
</script>
